import {
	logSendMergeFeatureTracking,
	logSubmitEventFeatureTracking,
	logTrackingWithPayload,
	serviceLogger
} from '../Logger';
import { Action, ActionName, Feature, FeatureStatus, submitEvent, submitMerge } from '@otto-ec/tracking-bct';
import { TrackingPayloadData } from './TrackingPayload';
import { Topic } from './Topic';
import { logger } from '@otto-ec/global-resources/debug';

const log = serviceLogger.scope('tracking-service');

export class TrackingService {

	sendMerge(trackingPayload: TrackingPayloadData, features: Feature[] = []) {
		if (features.length > 0) {
			submitMerge({}, features);
			logSendMergeFeatureTracking(log, features);
		} else {
			if (trackingPayload === null || trackingPayload === undefined) {
				return;
			}

			const trackingBCTRecord: Record<string, string[]> = this.toLinkedMap(trackingPayload);
			submitMerge(trackingBCTRecord);
			logTrackingWithPayload(log, 'tracking.bct.addToPageImpression', trackingPayload);
		}
	}

	sendEvent(trackingPayload: TrackingPayloadData, action?: Action) {
		if (action) {
			submitEvent({}, action);
			logSubmitEventFeatureTracking(log, action);
		} else {
			if (trackingPayload === null || trackingPayload === undefined) {
				return;
			}
			const trackingBCTRecord: Record<string, string[]> = this.toLinkedMap(trackingPayload);

			submitEvent(trackingBCTRecord);
			logTrackingWithPayload(log, 'tracking.bct.submitEvent', trackingPayload);
		}
	}

	sendFeatureTracking(
		topic: Topic,
		id: string,
		name: string,
		featureName: string,
		status: string,
		parentPosition: number,
		childrenPosition: number,
		labelsForParent: Record<string, string[]>,
		labelsForChildren: Record<string, string[]>,
		actionName: ActionName | null = null) {
		const features: Feature[] = [
			{
				id: id,
				name: name,
				status: status as FeatureStatus,
				position: parentPosition,
				labels: labelsForParent
			},
			{
				id: `${id}_tile`,
				name: featureName,
				parentId: id,
				status: status as FeatureStatus,
				position: childrenPosition,
				labels: labelsForChildren
			}
		];

		if (Topic.AddFeaturesToPageImpression == topic) {
			submitMerge({}, features);

			logSendMergeFeatureTracking(log, features);
		} else if (Topic.AddActionToEvent == topic) {

			const action = {
				name: actionName!,
				features: features
			} satisfies Action;

			submitEvent({}, action);
			logSubmitEventFeatureTracking(log, action);
		}
	}


	private toLinkedMap(map: TrackingPayloadData | Record<string, string>): Record<string, string[]> {
		if (!map) {
			return {};
		}
		const result: Record<string, string[]> = {};
		Object.keys(map).map((key) => {
			result[key] = [map[key]];
		});

		return result;
	}
}



